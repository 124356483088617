import { darken } from '@mui/material';

export default theme => ({
  appBarColor: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.background.paper
        : '#464748',
  },

  logoWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },

  logo: {
    height: '44px',
    marginLeft: -2,
    paddingRight: 20,

    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
  },
  appBarShift: {
    zIndex: 1300,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  toolbar: {
    '&.MuiToolbar-root': {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(3),
    },
  },
});
