import {
  Box,
  Chip,
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'redux-bundler-react';
import AppsIcon from '@mui/icons-material/Apps';
import CategoryIcon from '@mui/icons-material/Category';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CakeIcon from '@mui/icons-material/Cake';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HomeIcon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import AuthorizationCheck from '../../atoms/AuthorizationCheck';
import Facility from '../../../models/facility';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const styles = theme => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  facilityDetails: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  docsOuter: {
    padding: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  docsInner: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.action.selected,
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    '& >.MuiLink-root': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  chipNew: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

// eslint-disable-next-line
class ArchDrawerMenu extends Component {
  isRouteActive = key => {
    const {
      route: { drawItem },
    } = this.props;
    return drawItem === key;
  };

  render() {
    const { activeClient, activeOrNextFacility, baseUrl, classes } = this.props;
    return (
      <div className={classes.flex}>
        <div>
          {activeClient && (
            <List>
              <ListItem
                selected={this.isRouteActive('home')}
                button
                component="a"
                href={`/clients/${activeClient.id}`}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />

                <Chip
                  label="New"
                  color="primary"
                  size="small"
                  className={classes.chipNew}
                />
              </ListItem>
            </List>
          )}

          <AuthorizationCheck permissionName="ui.advanced.mode">
            <Collapse in={Boolean(activeOrNextFacility)}>
              {activeOrNextFacility && (
                <>
                  <Divider />
                  <List>
                    <ListItem
                      selected={this.isRouteActive('dashboard')}
                      button
                      component="a"
                      href={baseUrl}
                    >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem
                      selected={this.isRouteActive('workstations')}
                      button
                      component="a"
                      href={`${baseUrl}/workstations`}
                    >
                      <ListItemIcon>
                        <DesktopWindowsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Workstations" />
                    </ListItem>

                    <ListItem
                      selected={this.isRouteActive('compute')}
                      button
                      component="a"
                      href={`${baseUrl}/compute`}
                    >
                      <ListItemIcon>
                        <AppsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={activeClient?.computeName || 'Compute'}
                      />
                    </ListItem>

                    <ListItem
                      selected={this.isRouteActive('groups')}
                      button
                      component="a"
                      href={`${baseUrl}/groups`}
                    >
                      <ListItemIcon>
                        <GroupWorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Groups" />
                    </ListItem>

                    <ListItem
                      selected={this.isRouteActive('storage')}
                      button
                      component="a"
                      href={`${baseUrl}/storage`}
                    >
                      <ListItemIcon>
                        <StorageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Storage" />
                    </ListItem>
                  </List>

                  <AuthorizationCheck
                    permissionNames={[
                      'ui.images.view',
                      'ui.image.pipelines.view',
                      'ui.chef.packages.view',
                    ]}
                  >
                    <Divider />
                  </AuthorizationCheck>

                  <List>
                    <AuthorizationCheck permissionName="ui.templates.view">
                      <ListItem
                        button
                        component="a"
                        href={`${baseUrl}/templates`}
                        selected={this.isRouteActive('templates')}
                      >
                        <ListItemIcon>
                          <CategoryIcon />{' '}
                        </ListItemIcon>
                        <ListItemText primary="Templates" />

                        <Chip
                          label="New"
                          clickable
                          color="primary"
                          size="small"
                        />
                      </ListItem>
                    </AuthorizationCheck>

                    <AuthorizationCheck permissionName="ui.images.view">
                      <ListItem
                        button
                        component="a"
                        href={`${baseUrl}/images`}
                        selected={this.isRouteActive('images')}
                      >
                        <ListItemIcon>
                          <LayersIcon />
                        </ListItemIcon>
                        <ListItemText primary="Images" />
                      </ListItem>
                    </AuthorizationCheck>

                    <AuthorizationCheck permissionName="ui.image.pipelines.view">
                      <ListItem
                        button
                        component="a"
                        href={`${baseUrl}/image_pipelines`}
                        selected={this.isRouteActive('image_pipelines')}
                      >
                        <ListItemIcon>
                          <CakeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Image Pipelines" />
                      </ListItem>
                    </AuthorizationCheck>

                    <AuthorizationCheck permissionName="ui.chef.packages.view">
                      <ListItem
                        button
                        component="a"
                        href={`${baseUrl}/chef_packages`}
                        selected={this.isRouteActive('chef_packages')}
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Scripts" />
                      </ListItem>
                    </AuthorizationCheck>

                    <AuthorizationCheck permissionName="ui.licenses.view">
                      <ListItem
                        button
                        component="a"
                        href={`${baseUrl}/licenses`}
                        selected={this.isRouteActive('licenses')}
                      >
                        <ListItemIcon>
                          <CardMembershipIcon />
                        </ListItemIcon>
                        <ListItemText primary="Licenses" />
                      </ListItem>
                    </AuthorizationCheck>
                  </List>
                </>
              )}
            </Collapse>

            <AuthorizationCheck permissionNames={['ui.users.view']}>
              <Divider />
            </AuthorizationCheck>

            <AuthorizationCheck permissionName="ui.users.view">
              <List>
                <AuthorizationCheck permissionName="ui.users.view">
                  <ListItem
                    button
                    component="a"
                    href={`${baseUrl}/users`}
                    selected={this.isRouteActive('users')}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                </AuthorizationCheck>

                <AuthorizationCheck permissionName="ui.users.view">
                  <ListItem
                    button
                    component="a"
                    href={`${baseUrl}/teams`}
                    selected={this.isRouteActive('teams')}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Teams" />
                  </ListItem>
                </AuthorizationCheck>

                <ListItem
                  button
                  component="a"
                  href={`${baseUrl}/accounts`}
                  selected={this.isRouteActive('accounts')}
                >
                  <ListItemIcon>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Accounts" />
                </ListItem>
              </List>
            </AuthorizationCheck>

            <AuthorizationCheck permissionNames={['ui.clients.view']}>
              <Divider />
            </AuthorizationCheck>

            <List>
              <AuthorizationCheck permissionName="ui.clients.view">
                <ListItem
                  button
                  component="a"
                  href="/clients"
                  selected={this.isRouteActive('clients')}
                >
                  <ListItemIcon>
                    <BusinessCenterIcon />
                  </ListItemIcon>
                  <ListItemText primary="Organizations" />
                </ListItem>
              </AuthorizationCheck>

              <AuthorizationCheck permissionName="ui.roles.view">
                <ListItem
                  button
                  component="a"
                  href={`${baseUrl}/roles`}
                  selected={this.isRouteActive('roles')}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Roles" />
                </ListItem>
              </AuthorizationCheck>

              <AuthorizationCheck permissionName="ui.admin.view">
                <ListItem
                  button
                  component="a"
                  href={`/admin`}
                  selected={this.isRouteActive('admin')}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                </ListItem>
              </AuthorizationCheck>
            </List>
          </AuthorizationCheck>
        </div>

        {/* <AuthorizationCheck permissionName="ui.help.view"> */}

        <Box className={classes.docsOuter}>
          <Box className={classes.docsInner} id="whats-new">
            <Typography variant="body1" color="textSecondary">
              <strong>Whats New?</strong>
            </Typography>
          </Box>
          <Box className={classes.docsInner}>
            <Typography variant="body1" color="textSecondary">
              <strong>Need Help?</strong>
            </Typography>
            <Typography
              component={Link}
              href="https://archpt.zendesk.com/hc/en-us"
              color="secondary"
              target="_blank"
            >
              Check our docs
            </Typography>
          </Box>
          <Box className={classes.docsInner}>
            <Typography
              component={Link}
              href="https://archpt.io/privacy-policy"
              color="secondary"
              target="_blank"
            >
              Our privacy policy
            </Typography>
          </Box>
        </Box>
        {/* </AuthorizationCheck> */}
      </div>
    );
  }
}

ArchDrawerMenu.propTypes = {
  baseUrl: PropTypes.string,
  activeFacility: PropTypes.instanceOf(Facility),
};

export default connect(
  'selectActiveOrNextFacility',
  'selectRoute',
  'selectBaseUrl',
  'selectActiveClient',
  withStyles(ArchDrawerMenu, styles),
);
