import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import UserMenuContainer from '../UserMenu/UserMenuContainer';
import ClientPicker from '../ClientPicker';
import logo from './arch.svg';
import styles from './styles';

function MainToolbar({ classes, menu }) {
  const Menu = menu || UserMenuContainer;
  return (
    <AppBar
      data-test="main-toolbar"
      position="absolute"
      color="primary"
      classes={{ colorPrimary: classes.appBarColor }}
      className={classes.appBarShift}
    >
      <Toolbar className={classes.toolbar}>
        <a href="/" className={classes.logoWrapper}>
          <img src={logo} className={classes.logo} alt="Mane" />
        </a>

        <ClientPicker />

        <Menu />
      </Toolbar>
    </AppBar>
  );
}

MainToolbar.propTypes = {
  menu: PropTypes.node,
};

MainToolbar.defaultProps = {
  menu: null,
};

export default withStyles(MainToolbar, styles, { withTheme: true });
