import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import moment from 'moment-timezone';

import { ApplicationRecord } from './application_record';
import ChefCookbook from './chef_cookbook';
import ChefPackageAnalysis from './chef_package_analysis';
import ChefPackageSync from './chef_package_sync';
import Client from './client';
import GithubAppInstallation from './github_app_installation';

@Model()
export default class ChefPackage extends ApplicationRecord {
  static jsonapiType = 'chef_packages';
  @Attr() name: string;
  @Attr() url: string;
  @Attr() source: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() githubTemporaryCode: string;
  @Attr() repo: string;
  @Attr() branch: string;
  @Attr() public: string;
  @Attr({ persist: false }) ownership: string;
  @Attr({ persist: false }) repoList: string[];
  @Attr({ persist: false }) selected: boolean;

  @HasOne() pendingChefPackageSync: ChefPackageSync;
  @HasOne() completedChefPackageSync: ChefPackageSync;
  @HasOne() lastChefPackageSync: ChefPackageSync;
  @HasOne() lastAnalysis: ChefPackageAnalysis;
  @HasMany() cookbooks: ChefCookbook[];
  @BelongsTo() githubAppInstallation: GithubAppInstallation;
  @BelongsTo() client: Client;

  isSyncPending() {
    return this.lastChefPackageSync && this.lastChefPackageSync.isPending();
  }

  isSyncStarted() {
    return this.lastChefPackageSync && this.lastChefPackageSync.isStarted();
  }

  isSyncPendingOrStarted() {
    return (
      (this.lastChefPackageSync && this.lastChefPackageSync.isPending()) ||
      (this.lastChefPackageSync && this.lastChefPackageSync.isStarted())
    );
  }

  isSyncRecentlyCompleted() {
    const completed =
      this.lastChefPackageSync && this.lastChefPackageSync.isComplete();

    if (completed) {
      const now = moment(new Date());

      const duration = moment
        .duration(now.diff(moment.utc(this.lastChefPackageSync.createdAt)))
        .asMinutes();

      return duration < 60;
    }
    return false;
  }

  lastSyncTime() {
    return moment
      .utc(this.lastChefPackageSync.createdAt)
      .local()
      .calendar();
  }

  isSyncFailed() {
    return this.lastChefPackageSync && this.lastChefPackageSync.isFailed();
  }

  isGithub() {
    return this.source === 'github';
  }

  isS3() {
    return this.source === 's3';
  }

  isUpload() {
    return this.source === 'upload';
  }

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
