import {
  IconButton,
  Collapse,
  ListItem,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { darken } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import matchSorter from 'match-sorter';

import { TransitionGroup } from 'react-transition-group';

import ClientPrivacyMask from '../../atoms/ClientPrivacyMask';
import DropdownHeader from '../../molecules/DropdownHeader';
import DropdownToggler from '../../molecules/DropdownToggler';
import { withStyles } from 'tss-react/mui';
import { theme } from 'highcharts';

const StyledMenu = withStyles(
  props => (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      elevation={4}
      {...props}
    />
  ),
  {
    paper: {
      padding: 0,
      borderRadius: 0,
      minWidth: 300,
      width: '20%',
      marginTop: 50,
    },
  },
);

const styles = theme => ({
  search: {
    border: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    appearance: 'none',
    outline: 'none',
    backgroundColor: 'inherit',
  },
  dropdownToggler: {
    color: 'white',

    padding: theme.spacing(1),
  },

  selected: {
    backgroundColor:
      theme.palette.type === 'light'
        ? darken(theme.palette.background.paper, 0.06)
        : darken(theme.palette.background.paper, 0.1),
  },
});

function ClientPicker({ classes, doUpdateUrl, clientData, activeClient }) {
  const anchorRef = React.useRef(null);
  const [anchorEl, setAnchor] = useState(null);

  const inputEl = React.useRef(null);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [filteredClients, setFilteredClients] = useState([]);

  useHotkeys(
    'ctrl+shift+o',
    () => {
      setOpen(!open);
      if (!open) {
        inputEl.current.select();
      }
    },
    { enableOnFormTags: true, preventDefault: true },
    [open, inputEl],
  );
  const listItemRefCallback = React.useCallback(
    ref => {
      if (!ref) return;

      ref.scrollIntoView(false);
    },
    [selected],
  );

  useHotkeys(
    'down',
    () => {
      if (open) {
        if (filteredClients.length - 1 > selected) {
          setSelected(selected + 1);
        }
      }
    },
    { enableOnFormTags: true },
    [open, selected],
  );

  useHotkeys(
    'up',
    () => {
      if (open) {
        setSelected(Math.max(selected - 1, 0));
      }
    },
    { enableOnFormTags: true },
    [open, selected],
  );

  React.useEffect(() => {
    if (search !== '') {
      setFilteredClients(
        matchSorter(clientData, search.trim(), {
          keys: ['name'],
        }),
      );
      setSelected(0);
    } else {
      setFilteredClients(clientData);
    }
  }, [search, clientData]);

  return (
    <>
      <DropdownToggler
        className={classes.dropdownToggler}
        clickRef={anchorRef}
        open={open}
        onClick={e => setOpen(true)}
        title={
          <Typography variant="body1">
            {activeClient ? (
              <ClientPrivacyMask client={activeClient} />
            ) : (
              'Select Organization'
            )}
          </Typography>
        }
      />
      <StyledMenu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DropdownHeader
          search={search}
          onSearchChange={newSearch => setSearch(newSearch)}
          leftChild={
            <input
              ref={inputEl}
              placeholder="Search for organization"
              autoFocus
              autoSelect
              className={classes.search}
              value={search}
              onChange={e => setSearch(e.target.value)}
              onKeyDown={e => {
                console.log(e.key);
                if (e.key === 'Enter' && filteredClients[selected]) {
                  if (e.metaKey || e.ctrlKey) {
                    window.open(
                      `/clients/${filteredClients[selected].id}/dashboard`,
                      '_blank',
                    );
                  } else {
                    doUpdateUrl(
                      `/clients/${filteredClients[selected].id}/dashboard`,
                    );
                  }
                  setOpen(false);
                }
              }}
            />
          }
          rightChild={
            <IconButton
              onClick={() => (search ? setSearch('') : setOpen(false))}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <TransitionGroup>
          {filteredClients.map((client, index) => (
            <Collapse key={client.id}>
              <ListItem
                component="a"
                href={`/clients/${client.id}/dashboard`}
                button
                onClick={() => {
                  setOpen(false);
                }}
                dense
                divider
                ref={index === selected ? listItemRefCallback : null}
                className={index === selected && classes.selected}
              >
                <ListItemText
                  primaryTypographyProps={{ variant: 'h6' }}
                  color="textPrimary"
                  primary={<ClientPrivacyMask client={client} />}
                  secondary={`${client.facilityCount} Facilities, ${client.userCount} Users`}
                />
              </ListItem>
            </Collapse>
          ))}
        </TransitionGroup>
      </StyledMenu>
    </>
  );
}

// ClientPicker.propTypes = {
//   clientData: PropTypes.arrayOf(Client).isRequired,
//   activeClient: PropTypes.instanceOf(Client),
// };

export default connect(
  'doUpdateUrl',
  'selectClientData',
  'selectActiveClient',
  withStyles(ClientPicker, styles),
);
