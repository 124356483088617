import { Button } from '@mui/material';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  button: {
    marginLeft: theme.spacing(1),
  },
});

class ArchTableToolbarActionButton extends Component {
  render() {
    const { children, classes, ...rest } = this.props;
    return (
      <>
        <Button
          disableElevation
          variant="contained"
          color="inherit"
          {...rest}
          className={classes.button}
        >
          {children}
        </Button>
      </>
    );
  }
}

export default withStyles(ArchTableToolbarActionButton, styles, {
  withTheme: true,
});
