import { Attr, BelongsTo, HasMany, HasOne, Link, Model } from 'spraypaint';
import moment from 'moment-timezone';

import { ApplicationRecord } from './application_record';
import ChefPackageSync from './chef_package_sync';
import Image from './image';
import ImageDefinition from './image_definition';
import ImagePipeline from './image_pipeline';
import ImagePipelineSyncTransition from './image_pipeline_sync_transition';
import User from './user';

@Model()
export default class ImagePipelineSync extends ApplicationRecord {
  static jsonapiType = 'image_pipeline_syncs';

  @Attr() state: string;
  @Attr() currentStep: string;

  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() buildNumber: number;
  @Attr() invalidParameters: string[];
  @Attr() errorInfo: object;
  @BelongsTo() imagePipeline: ImagePipeline;
  @BelongsTo() imageDefinition: ImageDefinition;

  @Attr({ persist: false }) buildLog: string;
  @Attr({ persist: false }) instanceId: string;
  @Attr() hasBuildLog: boolean;
  @Attr() executionArn: string;
  @Attr() stepFunctionRegion: string;
  @Attr() analysis: object;
  @Attr() performedByName: string;
  @Attr() snapshotProgress: number;

  @HasMany() transitions: ImagePipelineSyncTransition[];
  @HasOne() image: Image[];
  @BelongsTo() performedBy: User[];
  @BelongsTo() chefPackageSync: ChefPackageSync;
  @BelongsTo() upstreamImagePipelineSync: ImagePipelineSync;

  @Link() cancel: string;

  durationForState(state: string) {
    const transition = this.transitions.find(t => t.toState === state);
    if (!transition) {
      return '';
    }

    if (!transition.duration) {
      const now = moment(new Date());
      const duration = moment.duration(
        now.diff(moment.utc(transition.createdAt)),
      );

      return this.formatDuration(duration.asSeconds());
    }

    return this.formatDuration(transition.duration);
  }

  sortedTransitions(): ImagePipelineSyncTransition[] {
    return [...this.transitions].sort((a, b) =>
      moment(a.createdAt).diff(moment(b.createdAt)),
    );
  }

  formatDuration(duration: number) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration - minutes * 60);
    if (minutes > 0 && seconds > 0) {
      return `${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  }

  isStateCompleted(state: string) {
    return (
      this.transitions.map(t => t.toState).includes(state) &&
      !this.isStateInProgress(state) &&
      !this.isStateFailed(state) &&
      !this.isStateCancelled(state) &&
      !this.isStateCancelling(state)
    );
  }

  isStatePending(state: string) {
    return !this.transitions.map(t => t.toState).includes(state);
  }

  isStateCancelling(state: string) {
    if (!this.isCancelling()) {
      return false;
    }

    const nextTransitions = this.sortedTransitions();
    nextTransitions.pop(); // Remove cancelling state
    const lastItem = nextTransitions.pop();
    console.log(lastItem && lastItem.toState);
    return lastItem && lastItem.toState === state;
  }
  isStateCancelled(state: string) {
    if (this.isStatePending(state) && this.isFailed()) {
      return true;
    }

    if (!this.isFailed()) {
      return false;
    }

    // if failed, and previous step is cancelled

    const nextTransitions = this.sortedTransitions();
    nextTransitions.pop(); // Remove failure state
    const lastItem = nextTransitions.pop();
    if (!(lastItem && lastItem.toState === 'cancelling')) {
      return false;
    }

    const secondLastItem = nextTransitions.pop();
    return secondLastItem && secondLastItem.toState === state;
  }

  isStateInProgress(state: string) {
    const nextTransitions = this.sortedTransitions();
    const lastItem = nextTransitions.pop();
    return lastItem && lastItem.toState === state;
  }

  isStateFailed(state: string) {
    if (!this.isFailed()) {
      return false;
    }

    const nextTransitions = this.sortedTransitions();
    nextTransitions.pop(); // Remove failure state
    const lastItem = nextTransitions.pop();
    return lastItem && lastItem.toState === state;
  }

  stepFunctionUrl() {
    return `https://${this.stepFunctionRegion}.console.aws.amazon.com/states/home?region=${this.stepFunctionRegion}#/executions/details/${this.executionArn}`;
  }

  isComplete() {
    return this.state === 'completed';
  }

  isPending() {
    return this.state === 'pending';
  }

  isStarted() {
    return this.state === 'started';
  }

  isFailed() {
    return this.state === 'failed';
  }

  isCancelling() {
    return this.state === 'cancelling';
  }

  isBuilding() {
    return this.state === 'building';
  }

  isImaging() {
    return this.state === 'imaging';
  }

  isInProgress() {
    return (
      this.isBuilding() ||
      this.isPending() ||
      this.isStarted() ||
      this.isImaging()
    );
  }
}
