import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    // height: 'calc(100% - 36px)',
    maxWidth: 1280,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  flex: {
    flex: '1 1',
    display: 'flex',
  },
  fullWidth: {
    maxWidth: '100%',
  },
});

class ArchPageContent extends Component {
  render() {
    const {
      children,
      classes,
      className,
      fullWidth,
      displayMode,
      flex,
    } = this.props;
    return (
      <div
        className={cx(
          classes.root,
          className,
          displayMode === 'wide' && classes.fullWidth,
          flex && classes.flex,
        )}
      >
        {children}
      </div>
    );
  }
}

ArchPageContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ArchPageContent.defaultProps = {
  className: '',
  fullWidth: true,
  flex: false,
};

export default connect(
  'selectDisplayMode',
  withStyles(ArchPageContent, styles, { withTheme: true }),
);
