export default theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  name: {
    marginRight: 20,

    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  identifier: {
    color: theme.palette.text.secondary,
    flexShrink: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  justify: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    overflow: 'hidden',
  },
  spinner: {
    width: [24, '!important'],
    height: [24, '!important'],
    marginRight: 8,
  },
  fieldWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  inputWithIcon: {
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    margin: theme.spacing(2),
    cursor: 'pointer',
  },
});
