import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import moment from 'moment-timezone';

import { ApplicationRecord } from './application_record';
import AvailabilityZone from './availability_zone';
import ChefPackage from './chef_package';
import Client from './client';
import Facility from './facility';
import Image from './image';
import ImageDefinition from './image_definition';
import ImagePipelineSync from './image_pipeline_sync';
import InstanceType from './instance_type';
import Keypair from './keypair';
import Subnet from './subnet';
import Vpc from './vpc';
import Template from './template';

@Model()
export default class ImagePipeline extends ApplicationRecord {
  static jsonapiType = 'image_pipelines';
  @Attr() name: string;
  @Attr() description: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() role: string;
  @Attr() ebsRaid: boolean;
  @Attr() enableFastLaunch: boolean;
  @Attr() autobuild: boolean;
  @Attr() platform: string;
  @Attr() sourceAmi: string;
  @Attr() runListLength: number;
  @Attr() ebsSize: number;
  @Attr() requireApproval: boolean;
  @Attr() enableAutoClean: boolean;
  @Attr() workingDirectory: string;
  @Attr() kmsKeyId: string;
  @Attr() osVersion: string;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() facility: Facility;
  @BelongsTo() client: Client;
  @BelongsTo() chefPackage: ChefPackage;
  @BelongsTo() instanceType: InstanceType;
  @BelongsTo() subnet: Subnet;
  @BelongsTo() vpc: Vpc;
  @BelongsTo() availabilityZone: AvailabilityZone;
  @BelongsTo() keypair: Keypair;
  @BelongsTo() sourceImagePipeline: ImagePipeline;
  @HasMany() downstreamImagePipelines: ImagePipeline[];
  @HasMany() templates: Template[];

  @HasOne() imageDefinition: ImageDefinition;
  @HasOne() lastImagePipelineSync: ImagePipelineSync;
  @HasOne() lastImage: Image;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }

  isSyncPending() {
    return this.lastImagePipelineSync && this.lastImagePipelineSync.isPending();
  }

  isSyncStarted() {
    return this.lastImagePipelineSync && this.lastImagePipelineSync.isStarted();
  }

  isSyncInProgress() {
    return (
      (this.lastImagePipelineSync && this.lastImagePipelineSync.isPending()) ||
      (this.lastImagePipelineSync && this.lastImagePipelineSync.isStarted()) ||
      (this.lastImagePipelineSync && this.lastImagePipelineSync.isBuilding())
    );
  }

  isSyncRecentlyCompleted() {
    const completed =
      this.lastImagePipelineSync && this.lastImagePipelineSync.isComplete();

    if (completed) {
      const now = moment(new Date());

      const duration = moment
        .duration(now.diff(moment.utc(this.lastImagePipelineSync.createdAt)))
        .asMinutes();

      return duration < 60;
    }
    return false;
  }

  lastSyncTime() {
    return moment
      .utc(this.lastImagePipelineSync.updatedAt)
      .local()
      .calendar();
  }

  lastSyncCreatedTime() {
    return moment
      .utc(this.lastImagePipelineSync.createdAt)
      .local()
      .calendar();
  }

  isSyncFailed() {
    return this.lastImagePipelineSync && this.lastImagePipelineSync.isFailed();
  }
}
