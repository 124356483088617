import { makeStyles } from 'tss-react/mui';
import React from 'react';
import ArchTableContext from '../../organisms/ArchTable/ArchTableContext';

import { TableCell } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  tableCell: {
    flex: 1,
    textDecoration: 'none',
    color: theme.palette.text.primary,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    whiteSpace: 'nowrap',
  },
  clickableCell: {
    cursor: 'pointer',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },

  paddingCheckbox: {
    width: 48, // prevent the checkbox column from growing
    padding: '0 0 0 0',
    '&:last-child': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function ArchTableCell({
  children,
  padding = 'default',
  onClick,
  clickable,
  href,
  rightAlign,
}) {
  const { classes, cx } = useStyles();

  return (
    <ArchTableContext.Consumer>
      {rowHeight => (
        <TableCell
          className={cx(
            classes.tableCell,
            classes.flexContainer,
            clickable && classes.clickableCell,
          )}
          align={rightAlign ? 'right' : 'left'}
          component={href ? 'a' : 'div'}
          style={{ height: rowHeight }}
          padding={padding}
          onClick={!href ? onClick : null}
          href={href}
          classes={{ paddingCheckbox: classes.paddingCheckbox }}
        >
          {children}
        </TableCell>
      )}
    </ArchTableContext.Consumer>
  );
}
