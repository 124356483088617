import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import { Skeleton } from '@mui/lab';
import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as yup from 'yup';
import { withStyles } from 'tss-react/mui';

import ArchPageContent from '../../molecules/ArchPageContent';
import Breadcrumbs, { Breadcrumb } from '../../organisms/Breadcrumbs';
import Client from '../../../models/client';
import PageTitle from '../../atoms/PageTitle';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  halfWidthField: {
    width: '50%',
    '&:first-child': {
      paddingRight: 16,
    },
    '&:last-child': {
      paddingLeft: 16,
    },
  },
  checkboxesGroup: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
});

export const mapErrorsToFormik = errors =>
  Object.keys(errors).reduce(
    (memo, key) => ({ ...memo, [key]: errors[key].message }),
    {},
  );

class EditAccount extends Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { doUpdateUrl, baseUrl } = this.props;
    try {
      const { doUpdateAccount } = this.props;
      const account = await doUpdateAccount(values);

      setSubmitting(false);
      console.log(account);
      if (account.isPersisted) {
        doUpdateUrl(`${baseUrl}/accounts/${account.id}`);
      } else {
        setErrors(mapErrorsToFormik(account.errors));
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  render() {
    const { classes, baseUrl, activeClient, activeAccount } = this.props;

    if (!activeClient) {
      return (
        <ArchPageContent>
          <Container>
            <Skeleton />
          </Container>
        </ArchPageContent>
      );
    }
    return (
      <>
        <PageTitle>Edit Account</PageTitle>
        <Breadcrumbs>
          <Breadcrumb to={`${baseUrl}/account`}>Accounts</Breadcrumb>
          <Breadcrumb>Edit</Breadcrumb>
        </Breadcrumbs>
        <ArchPageContent>
          <Container>
            <Formik
              enableReinitialize
              initialValues={{
                name: activeAccount.name || '',
                email: activeAccount.email || '',
                accountId: activeAccount.accountId || '',
                roleArn: activeAccount.roleArn || '',
                kmsKeyId: activeAccount.kmsKeyId || '',
                enableLeastPrivilegeIam:
                  activeAccount.enableLeastPrivilegeIam || false,
                enableLambdaPermissionBoundary:
                  activeAccount.enableLambdaPermissionBoundary || false,
                stackPrefix: activeAccount.stackPrefix || '',
                secretPrefix: activeAccount.secretPrefix || '',
              }}
              validationSchema={yup.object().shape({
                name: yup.string().required(),
                email: yup
                  .string()
                  .required()
                  .email(),
                accountId: yup.string().required(),
              })}
              onSubmit={this.handleSubmit}
            >
              {({ isValid, values }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Edit Account
                      </Typography>
                      <Divider />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle1">
                        Account Overview
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Card>
                        <CardContent>
                          <Field
                            name="accountId"
                            label="Account ID"
                            component={TextField}
                            fullWidth
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            placeholder="000000000000"
                          />
                          <Field
                            name="name"
                            label="Name"
                            component={TextField}
                            fullWidth
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                          />
                          <Field
                            name="email"
                            label="Email"
                            component={TextField}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            placeholder="aws@example.com (optional)"
                          />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle1">
                        Account Configuration
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Card>
                        <CardContent>
                          <div className={classes.checkboxesGroup}>
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="enableLeastPrivilegeIam"
                              Label={{
                                label: 'Enable Least Privilege IAM Mode',
                              }}
                            />
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="enableLambdaPermissionBoundary"
                              Label={{
                                label: 'Enable Lambda Permission Boundaries',
                              }}
                            />
                          </div>

                          <Field
                            name="roleArn"
                            label="Role Arn"
                            helperText="Role Arn is required for Arch managed accounts to enable automatic IAM Role deployment"
                            component={TextField}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                          <Field
                            name="kmsKeyId"
                            label="KMS Key Arn"
                            component={TextField}
                            helperText="If a KMS Key is not provided, one will be generated for each facility. Seperate multiple keys with commas"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                          <Field
                            name="secretPrefix"
                            label="Secret Prefix"
                            component={TextField}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                          <Field
                            name="stackPrefix"
                            label="Stack Prefix"
                            component={TextField}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <div className={classes.buttons}>
                        <Button
                          variant="contained"
                          size="small"
                          className={classes.button}
                          component="a"
                          color="inherit"
                          href={`${baseUrl}/accounts/${activeAccount.id}`}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={!isValid}
                          variant="contained"
                          size="small"
                          color="primary"
                          className={classes.button}
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Container>
        </ArchPageContent>
      </>
    );
  }
}

EditAccount.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  activeClient: PropTypes.instanceOf(Client).isRequired,
  doUpdateUrl: PropTypes.func.isRequired,
  doUpdateAccount: PropTypes.func.isRequired,
};

export default connect(
  'doUpdateUrl',
  'doUpdateAccount',
  'selectActiveClient',
  'selectBaseUrl',
  'selectActiveAccount',
  withStyles(EditAccount, styles, { withTheme: true }),
);
