import { Model, Attr, HasMany } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Account from './account';
import ClientUserAssignment from './client_user_assignment';
import Facility from './facility';
import User from './user';

import { faker } from '@faker-js/faker';

@Model()
export default class Client extends ApplicationRecord {
  static jsonapiType = 'clients';
  @Attr() name: string;
  @Attr() internalName: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr() userCount: number;
  @Attr() facilityCount: number;
  @Attr() enabledFacilityCount: number;
  @Attr() onlineWorkstationCount: number;
  @Attr() totalWorkstationCount: number;
  @Attr() enableLeastPrivilegeIam: boolean;
  @Attr() privacyMode: boolean;
  @Attr() workosConnectionId: string;
  @Attr() workosProvider: string;
  @Attr() domain: string;
  @Attr() computeName: string;

  @Attr() onlineRenderCount: number;
  @Attr() totalRenderCount: number;

  @HasMany() users: User[];
  @HasMany() accounts: Account[];
  @HasMany() facilities: Facility[];

  @HasMany() clientUserAssignments: ClientUserAssignment[];

  constructor(attrs?: Record<string, any>) {
    const defaults = { selected: false };
    super({ ...defaults, ...attrs });
  }

  clientNameOrFake(fake: Boolean): string {
    if (fake && this.internalName) {
      return this.internalName;
    }

    if (fake) {
      Client.seedFaker(this.name);
      return faker.company
        .companyName()
        .split(' ')[0]
        .replace(/,/, '');
    }
    return this.name;
  }

  static seedFaker(seed: String) {
    const intSeed = seed
      .split('')
      .reverse()
      .map(c => c.charCodeAt(0))
      .reduce((a, b) => a + b, 0);

    faker.seed(intSeed);
  }

  hasParsec(): boolean {
    return this.facilities.reduce(
      (memo, facility) => facility.enableParsec || memo,
      false,
    );
  }

  hasTeradici(): boolean {
    return this.facilities.reduce(
      (memo, facility) => facility.enableTeradici || memo,
      false,
    );
  }

  roleCfnDeploymentLink(): string {
    return `https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/create/review?templateURL=https://arch-roles-template.s3.us-west-2.amazonaws.com/arch-roles.yml&stackName=arch-roles&param_ArchAccountID=520148785661&param_ExternalID=${this.id}&param_StackPrefix=arch&param_SecretPrefix=/arch`;
  }
}
