import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import Template from '../models/template';
import TemplateSecurityGroupAssignment from '../models/template_security_group_assignment';

export default {
  name: 'templates',
  getReducer: () => {
    const initialData = {
      loading: false,
      items: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      activeTemplate: null,
      loadingActiveTemplate: false,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_TEMPLATES') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_TEMPLATES_SUCCESS') {
        return {
          ...state,
          loading: false,
          items: payload.result,
        };
      }
      if (type === 'CHANGE_TEMPLATE_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'FETCH_TEMPLATE_START') {
        return { ...state, loadingActiveTemplate: true };
      }
      if (type === 'FETCH_TEMPLATE_SUCCESS') {
        return {
          ...state,
          loadingActiveTemplate: false,
          activeTemplate: payload.result,
        };
      }
      if (type === 'UPDATE_TEMPLATE') {
        return {
          ...state,
          activeTemplate: payload.template,
        };
      }
      if (type === 'REMOVE_TEMPLATE') {
        return {
          ...state,
          activeTemplate: null,
        };
      }
      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          items: null,
        };
      }
      if (type === 'RESET_ACTIVE_TEMPLATE') {
        return {
          ...state,
          activeTemplate: null,
        };
      }

      return state;
    };
  },

  doFetchTemplates: () => async ({ dispatch, getState, store }) => {
    const state = getState();

    const { sortBy } = state.templates;
    const { sortDirection } = state.templates;

    const facility = store.selectActiveFacility(state);
    dispatch({ type: 'FETCH_TEMPLATES' });
    const scope = Template.where({
      kept: true,
      facility_id: facility.id,
    })
      .order({
        [snakeCase(sortBy)]: sortDirection.toLowerCase(),
      })
      .includes([
        'image_pipeline',
        'image',
        'instance_type',
        { image_pipeline: ['last_image'] },
      ]);

    const response = await scope.all();

    dispatch({
      type: 'FETCH_TEMPLATES_SUCCESS',
      payload: { result: response.data },
    });
  },

  doFetchTemplate: templateId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_TEMPLATE_START' });

    const response = await Template.includes([
      'instance_type',
      'image',
      { image_pipeline: 'last_image' },
      'subnet',
      'keypair',
      'availability_zone',
      'vpc',
      'security_groups',
      { template_security_group_assignments: 'security_group' },
    ]).find(templateId);
    dispatch({
      type: 'FETCH_TEMPLATE_SUCCESS',
      payload: { result: response.data },
    });
  },

  doChangeTemplatesSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_TEMPLATE_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchTemplates();
  },

  doCreateTemplate: values => async ({ dispatch, getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    const templateSecurityGroupAssignments = values.securityGroups.map(
      sg => new TemplateSecurityGroupAssignment({ securityGroup: sg }),
    );

    const template = new Template({
      facility,
      ...values,
      templateSecurityGroupAssignments,
    });

    await template.save({
      with: [
        'facility.id',
        'image.id',
        'imagePipeline.id',
        'keypair.id',
        'vpc.id',
        'availabilityZone.id',
        'instanceType.id',
        'subnet.id',
        { templateSecurityGroupAssignments: 'securityGroup.id' },
      ],
    });

    if (template.isPersisted) {
      facility.templates.push(template);

      await store.doFetchTemplates();
    }

    return template;
  },

  doUpdateTemplate: values => async ({ dispatch, getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    const template = store.selectActiveTemplate(state).dup();
    const tsgasToDestroy = template.templateSecurityGroupAssignments
      .filter(
        ({ securityGroup: { id } }) =>
          !values.securityGroups.map(sg => sg.id).includes(id),
      )
      .map(tsga => {
        const nextTsga = tsga;
        nextTsga.isMarkedForDestruction = true;
        return nextTsga;
      });
    const tsgasToCreate = values.securityGroups
      .filter(
        ({ id }) =>
          !template.templateSecurityGroupAssignments
            .map(({ securityGroup: sg }) => sg.id)
            .includes(id),
      )
      .map(sg => new TemplateSecurityGroupAssignment({ securityGroup: sg }));

    const { image, imagePipeline, ...otherValues } = values;

    if (image === null && template.image) {
      template.image.isMarkedForDisassociation = true;
    } else {
      template.image = image;
    }

    if (imagePipeline === null && template.imagePipeline) {
      template.imagePipeline.isMarkedForDisassociation = true;
    } else {
      template.imagePipeline = imagePipeline;
    }

    template.assignAttributes({
      ...otherValues,
      templateSecurityGroupAssignments: [...tsgasToDestroy, ...tsgasToCreate],
    });

    await template.save({
      with: [
        'facility.id',
        'image.id',
        'imagePipeline.id',
        'keypair.id',
        'vpc.id',
        'availabilityZone.id',
        'instanceType.id',
        'subnet.id',
        { templateSecurityGroupAssignments: 'securityGroup.id' },
      ],
    });

    if (template.isPersisted) {
      await store.doFetchTemplate(template.id);
      await store.doFetchFacility(facility.id);
    }

    return template;
  },

  doDestroyTemplate: template => async ({ dispatch, store, getState }) => {
    const state = getState();
    const nextTemplate = template.dup();
    await nextTemplate.destroy();
    dispatch({ type: 'REMOVE_TEMPLATE', payload: { template: nextTemplate } });
    await store.doFetchTemplates();
  },

  doClearActiveTemplate: () => async ({ dispatch, store, getState }) => {
    dispatch({ type: 'REMOVE_TEMPLATE' });
  },

  doResetActiveTemplate: () => async ({ dispatch }) => {
    dispatch({ type: 'RESET_ACTIVE_TEMPLATE' });
  },

  selectTemplateState: state => state.templates,
  selectActiveTemplate: createSelector(
    'selectTemplateState',
    templateState => templateState.activeTemplate,
  ),

  selectTemplateId: createSelector(
    'selectQueryObject',
    queryObject => queryObject.templateId,
  ),

  reactShouldFetchActiveTemplate: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveTemplate',
    'selectTemplateState',
    (routeParams, pathname, activeTemplate, templateState) => {
      if (
        !pathname.includes('/templates') ||
        !routeParams.templateId ||
        templateState.loadingActiveTemplate
      ) {
        return null;
      }
      if (activeTemplate && activeTemplate.id === routeParams.templateId) {
        return null;
      }

      return {
        actionCreator: 'doFetchTemplate',
        args: [routeParams.templateId],
      };
    },
  ),

  reactShouldFetchTemplates: createSelector(
    'selectRouteApis',
    'selectTemplatesRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveFacility',
    (apis, templatesRaw, currentUser, activeClient, activeFacility) => {
      const wantsTemplates = apis.includes('templates');
      if (
        !wantsTemplates ||
        templatesRaw.loading ||
        templatesRaw.items ||
        !currentUser ||
        !activeClient ||
        !activeFacility
      ) {
        return false;
      }
      return { actionCreator: 'doFetchTemplates' };
    },
  ),
  reactShouldResetActiveTempate: createSelector(
    'selectActiveTemplate',
    'selectRouteParams',
    (activeTemplate, routeParams) => {
      if (!activeTemplate) {
        return false;
      }
      if (routeParams.templateId) {
        return false;
      }
      return { actionCreator: 'doResetActiveTemplate' };
    },
  ),

  selectTemplatesRaw: state => state.templates,
  selectTemplates: state => state.templates.items || [],
  selectTemplatesLoading: state => state.templates.loading,

  selectTemplatesSortBy: state => state.templates.sortBy,
  selectTemplatesSortDirection: state => state.templates.sortDirection,
};
