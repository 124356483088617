import { Container, CssBaseline, Drawer } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'redux-bundler-react';
import { getNavHelper } from 'internal-nav-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Client from '../../../models/client';
import MainToolbar from '../../organisms/MainToolbar';
import FacilityToolbar from '../FacilityToolbar';

const drawerWidth = 240;
const drawerWidthMobile = 56;

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of MemberExpression.
const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
  },
  page: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: 'calc(100vh - 64px)',
    marginTop: 64,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: drawerWidthMobile,
      overflowX: 'hidden',
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  container: {
    paddingBottom: theme.spacing(4),
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
});

class ArchPage extends Component {
  render() {
    const {
      children,
      classes,
      doUpdateUrl,
      drawerMenu,
      activeClient,
      activeOrNextFacility,
    } = this.props;
    const DrawerMenu = drawerMenu || null;
    return (
      <div className={classes.root} onClick={getNavHelper(doUpdateUrl)}>
        <CssBaseline />
        <MainToolbar />
        {DrawerMenu && (
          <Drawer
            variant="persistent"
            open={Boolean(activeClient)}
            classes={{ paper: classes.drawerPaper }}
          >
            {/* <div className={classes.toolbarIcon} /> */}
            {DrawerMenu && <DrawerMenu />}
          </Drawer>
        )}

        <div className={classes.page}>
          <div className={classes.appBarSpacer} />
          {activeClient && activeOrNextFacility && <FacilityToolbar />}
          <Container maxWidth={false} className={classes.container}>
            {children}
          </Container>
        </div>
      </div>
    );
  }
}

ArchPage.propTypes = {
  children: PropTypes.node.isRequired,
  doUpdateUrl: PropTypes.func.isRequired,
  drawerMenu: PropTypes.func, //PropTypes.instanceOf(Component),
  activeClient: PropTypes.instanceOf(Client),
};

ArchPage.defaultProps = {
  drawerMenu: null,
};

export default connect(
  'doUpdateUrl',
  'selectActiveClient',
  'selectActiveFacility',
  'selectActiveOrNextFacility',
  withStyles(ArchPage, styles, { withTheme: true }),
);
