import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cx from 'classnames';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  title: {
    cursor: 'pointer',
    alignSelf: 'stretch',
    display: 'inline-flex',
    alignItems: 'center',
  },
  arrowIcon: {
    transition: 'transform 0.25s',
  },
  flippedArrowIcon: {
    transform: 'rotate(180deg)',
  },
});

function DropdownToggler({
  classes,
  open,
  onClick,
  title,
  clickRef,
  className,
}) {
  return (
    <div
      className={cx(classes.title, className)}
      onClick={onClick}
      ref={clickRef}
    >
      {title}{' '}
      {open ? (
        <KeyboardArrowDownIcon
          className={cx(classes.arrowIcon, classes.flippedArrowIcon)}
        />
      ) : (
        <KeyboardArrowDownIcon className={classes.arrowIcon} />
      )}
    </div>
  );
}

DropdownToggler.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  // title: PropTypes.string.isRequired,
};

export default withStyles(DropdownToggler, styles, { withTheme: true });
